import { Snackbar, Alert } from '@mui/material'

import { globalStore } from '../../store/globalStore'

export const SnackbarAlert = () => {
  const {
    snackbarAlert: { openSnackBar, variant, severity, message, autoHideDuration },
    closeSnackbarAlert,
  } = globalStore()

  return (
    <Snackbar
      open={openSnackBar}
      autoHideDuration={autoHideDuration}
      onClose={closeSnackbarAlert}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={closeSnackbarAlert} severity={severity} variant={variant} elevation={6} sx={{ width: '100%' }}>
        {message || ''}
      </Alert>
    </Snackbar>
  )
}
