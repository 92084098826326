import { AlertColor } from '@mui/material'
import { produce } from 'immer'
import { ReactElement } from 'react'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export type SnackBarAlert = {
  openSnackBar: boolean
  variant?: 'filled' | 'outlined' | 'standard'
  severity: AlertColor
  message: string
  autoHideDuration?: number | null
}

export type DialogModal = {
  titleDialog: string | null
  open: boolean
  content: ReactElement | null
}

export type InitialGlobalState = {
  height: number | null
  dialogModal: DialogModal
  snackbarAlert: SnackBarAlert
}

export type Global = InitialGlobalState & {
  setHeight?: (height: number) => void
  openDialog: ({ titleDialog, content }: { titleDialog: string | null; content: React.ReactNode }) => void
  closeDialog: () => void
  openSnackbarAlert?: (values: SnackBarAlert) => void
  closeSnackbarAlert?: () => void
}

const initialGlobalState: InitialGlobalState = {
  height: null,
  dialogModal: {
    titleDialog: null,
    open: false,
    content: null,
  },
  snackbarAlert: {
    openSnackBar: false,
    variant: 'filled',
    severity: 'info',
    message: '',
    autoHideDuration: null,
  },
}

export const globalStore = create<Global>()(
  devtools(
    (set) => ({
      ...initialGlobalState,
      openDialog: ({ titleDialog, content }) =>
        set(
          produce((draft: Global) => {
            draft.dialogModal.open = true
            draft.dialogModal.titleDialog = titleDialog
            draft.dialogModal.content = content as ReactElement
          }),
          false,
          'openDialog',
        ),
      closeDialog: () =>
        set(
          produce((draft: Global) => {
            draft.dialogModal = initialGlobalState.dialogModal
          }),
          false,
          'closeDialog',
        ),
      setHeight: (height) =>
        set(
          produce((draft: Global) => {
            draft.height = height
          }),
          false,
          'setHeight',
        ),
      openSnackbarAlert: ({ severity, message, autoHideDuration }) =>
        set(
          produce((draft: Global) => {
            draft.snackbarAlert.openSnackBar = true
            draft.snackbarAlert.severity = severity
            draft.snackbarAlert.message = message
            draft.snackbarAlert.autoHideDuration = autoHideDuration ?? 2000
          }),
          false,
          'openSnackbarAlert',
        ),
      closeSnackbarAlert: () =>
        set(
          produce((draft: Global) => {
            draft.snackbarAlert.openSnackBar = false
          }),
          false,
          'closeSnackbarAlert',
        ),
    }),
    { name: 'Global' },
  ),
)
